import React from 'react'
// import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
// import Banner from '../components/Banner'
//
// import ContactForm from '../components/ContactForm'


class ContactPage extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="Contact Us  | Great Harbour"
                    meta={[
                        { name: 'description', content: '' },
                    ]}
                >
                </Helmet>




                <div id="sub">

                    <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2>Contact Us</h2>
                            </header>

                        </div>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default ContactPage
